import { Pipe, PipeTransform } from '@angular/core';
import { KurzUnitService } from '../../services/kurz-unit.service';

@Pipe({
  name: 'kurzDate'
})
export class KurzDatePipe implements PipeTransform {

  constructor(private kurzUnitService: KurzUnitService) {}

  transform(isoDate: string, explicitLang?: string): string {

    const res = this.kurzUnitService.getFormattedDate(isoDate, explicitLang);

    return res;
  }

}
